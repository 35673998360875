/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Container from "../components/container"
import BlockContent from "@sanity/block-content-to-react"
import Serializers from "../components/serializers/serializers"
import { GatsbyImage } from "gatsby-plugin-image"
import SocialLinks from "../components/atoms/SocialLinks"
import FooterForm from "./footerForm"

class Footer extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            companyLogo: sanitySiteSettings {
              logo {
                asset {
                  gatsbyImageData
                }
              }
            }
            digettlogo: file(name: { eq: "digettlogo" }) {
              name
              publicURL
            }
            footer: sanityFooter {
              item {
                _rawBody(resolveReferences: { maxDepth: 10 })
              }
            }
          }
        `}
        render={data => (
          <footer
            sx={{
              backgroundColor: "#686A6E",
              color: "antler",
              padding: "80px 0px 80px 0px",
              a: {
                color: "blueLight",
                textDecoration: "underline !important",
              },
            }}
          >
            <Container>
              <h2 sx={{ marginBottom: "1rem", fontSize: "48px !important" }}>
                Stay up to date.
              </h2>

              <div
                sx={{
                  display: "flex",
                  flexDirection: ["column", "column", "row"],
                  gap: ["3rem"],
                  flexWrap: "wrap",
                  alignItems: ["center", "center", "flex-start"],
                  justifyContent: ["", "", "space-between"],
                  "> div": {
                    width: "calc(100% / 3 - 20px)",
                    boxSizing: "border-box",
                    "&:nth-child(1)": {
                      width: ["100%", "100%", "30%"],
                    },
                    "&:nth-child(2)": {
                      width: ["100%", "100%", "35%"],
                    },
                    "&:nth-child(3)": {
                      width: ["100%", "100%", "20%"],
                      textAlign: ["center", "center", "right"],
                    },
                  },
                }}
              >
                <div
                  sx={{
                    h2: {
                      margin: "0px 0px 10px 0px",
                      fontSize: "44px",
                      fontWeight: "600",
                    },
                    form: {
                      width: ["100%"],
                      input: {
                        border: "1px solid",
                        borderColor: "spanishGray",
                        background: "transparent",
                        marginBottom: "15px",
                        padding: "12px 15px !important",
                        color: "antler",
                        width: "100%",
                        maxWidth: "100%",
                        boxSizing: "border-box",
                        "::placeholder": {
                          color: "antler",
                          fontSize: "16px",
                        },
                      },
                    },
                  }}
                >
                  <div sx={{ marginBottom: "15px" }}>
                    Get notified of our new listings.
                  </div>
                  <div
                    sx={{
                      ".ctct-form-success": {
                        h2: {
                          color: "#EBE6E0 !important",
                        },
                        p: {
                          color: "#EBE6E0 !important",
                        },
                      },
                      ".ctct-form-container": {
                        ".ctct-form-defaults": {
                          backgroundColor: "transparent !important",
                          padding: "0px !important",
                          color: "#EBE6E0 !important",
                          ".ctct-form-field": {
                            margin: "0px",
                          },
                          label: {
                            color: "#EBE6E0 !important",
                            marginBottom: "3px !important",
                            "&:before": {
                              display: "none",
                            },
                          },
                          "input[type='text'],input[type='email']": {
                            border: "1px solid",
                            borderColor: "spanishGray",
                            background: "transparent",
                            marginBottom: "15px",
                            padding: "12px 15px!important",
                            color: "antler",
                            width: "100%",
                            maxWidth: "100%",
                            boxSizing: "border-box",
                          },
                          "#gdpr_text": {
                            display: "none",
                          },
                          button: {
                            color: "antler !important",
                            backgroundColor:
                              "rgba(18, 48, 105, 0.7) !important",
                            border: "0px solid red !important",
                            width: [
                              "auto !important",
                              "auto !important",
                              "auto !important",
                            ],
                            borderRadius: "18px !important",
                            fontWeight: "700 !important",
                            fontSize: "14px !important",
                            padding: ["10px 30px !important"],
                            marginLeft: "auto",
                            marginRight: "0",
                          },
                        },
                      },
                    }}
                  >
                    <FooterForm />
                  </div>
                </div>
                <div
                  sx={{
                    lineHeight: "24px",
                    a: {
                      marginTop: "20px",
                      display: "block",
                      maxWidth: "380px",
                      color: "#9FC9F4 !important",
                    },
                  }}
                >
                  <div>
                    Simpson Ranches & Land, LLC specializes in hunting ranches,
                    live water properties, agricultural acreage and recreational
                    properties throughout Texas and New Mexico.
                  </div>
                  <a href="/CN-1-3.pdf">
                    Texas Real Estate Commission Consumer Protection Notice
                  </a>
                  <a href="/Information-About-Brokerage-Services.pdf">
                    Information About Brokerage Services
                  </a>
                </div>

                <div
                  sx={{
                    div: {
                      marginBottom: "2px",
                    },
                  }}
                >
                  <div>Simpson Ranches & Land</div>
                  <div>984 Bear Creek Rd.</div>
                  <div>Kerrville, Texas 78028</div>
                  <div>Phone (830) 955-1725</div>
                  <div>Fax (210) 881-6756</div>
                  <div
                    sx={{
                      marginTop: "1rem",
                      display: "flex",
                      justifyContent: ["center", "flex-end"],
                    }}
                  >
                    <SocialLinks />
                  </div>
                </div>
              </div>
              <div
                sx={{
                  display: "flex",
                  flexDirection: ["column", "row", "row"],
                  gap: "4rem",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "spanishGray",
                  padding: "30px 40px",
                  marginTop: "75px",
                  borderRadius: "6px",
                  fontSize: "12px",
                  lineHeight: "14.5px",
                }}
              >
                <div className="text">
                  <p>
                    All information provided is deemed reliable, but is not
                    guaranteed and should be independently verified.
                  </p>
                  <p>
                    Real Estate buyers are hereby notified that real properties
                    and its rights and amenities in the State of Texas are
                    subject to many forces and impacts whether natural, those
                    caused by man, or otherwise; including, but not limited to,
                    drought or other weather related events, disease (e.g. Oak
                    Wilt or Anthrax), invasive species, illegal trespassing,
                    previous owner actions, neighbor actions and government
                    actions. Prospective buyers of Texas real estate should
                    investigate any concerns regarding a specific real property
                    to their satisfaction.
                  </p>
                  <p>
                    Buyer’s brokers must be identified on first contact, and
                    must accompany buying prospect on first showing to be
                    allowed full fee participation. If this condition is not
                    met, fee participation will be at the sole discretion of
                    Simpson Ranches & Land, LLC, Broker.
                  </p>
                  <p>
                    Simpson Ranches & Land, LLC reserves the right to require
                    any or all interested buyer(s) of a particular property to
                    provide proof of financial ability to purchase said property
                    prior to the initial showing or any subsequent showing of
                    the property. Simpson Ranches & Land, LLC also reserves the
                    right to refuse to show a property to a potential buyer for
                    any reason at Simpson Ranches & Land, LLC’s sole and
                    absolute discretion.
                  </p>
                </div>

                <GatsbyImage
                  image={data.companyLogo.logo.asset.gatsbyImageData}
                  alt="Footer Image"
                  sx={{
                    width: ["", "100%", "55%"],
                    height: ["100%", ""],
                  }}
                />
              </div>
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "50px",
                }}
              >
                <div>
                  Copyright © 2012-{new Date().getFullYear()} · Simpson Ranches
                  & Land, LLC · Leaders in Texas Ranch Sales
                </div>
                <div
                  sx={{
                    a: {
                      color: "white",
                      textDecoration: "none !important",
                      marginTop: "10px",
                      display: "block",
                    },
                  }}
                >
                  <Link to="/sitemap">Sitemap</Link>
                </div>
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div>Site by </div>
                  <div
                    sx={{
                      marginLeft: "8px",
                    }}
                  >
                    <a
                      sx={{
                        display: "flex",
                        paddingTop: "3px",
                      }}
                      href="https://www.digett.com"
                      title="Digett"
                    >
                      <img
                        src={data.digettlogo.publicURL}
                        alt="Footer Logos"
                        title="Footer Logos"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </footer>
        )}
      />
    )
  }
}

export default Footer
