/** @jsx jsx */
import { jsx } from "theme-ui"
import axios from "axios"
import { useState } from "react"
import { Helmet } from "react-helmet"

const MyForm = ({ title, contacts }) => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    success: false,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      setServerState({
        success: true,
      })
      if (typeof window !== "undefined") {
        // window.location.href = "/contact-thank-you"
      }
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (e.target[2].value === "") {
      setServerState({ submitting: true })
      axios({
        method: "post",
        url: "https://getform.io/f/6ee90c20-a100-4929-be38-54280c646e0b",
        data: new FormData(form),
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form)
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form)
        })
    } else {
      console.log("failed")
    }
  }

  return (
    <div>
      <div>
        {!serverState.success ? (
          <form
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",

              input: {
                border: "1px solid #484242",
                color: "#484242",
                backgroundColor: "transparent",
                padding: "8px 20px",
                fontSize: "1rem",
                width: "100%",
                borderRadius: "0px !important",
                boxSizing: "border-box",
                "&::placeholder": {
                  color: "#484242",
                },
                "&:focus-visible": {
                  borderRadius: "0px !important",
                  outline: "none !important",
                },
              },
              button: {
                color: "antler",
                backgroundColor: "rgba(18, 48, 105, 0.7)",
                border: "0px solid red",
                width: ["auto", "auto", "auto"],
                borderRadius: "18px",
                fontWeight: "700",
                padding: ["10px 30px !important"],
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#18459A",
                },
              },

              ".label-input": {
                width: "100%",
              },
            }}
            onSubmit={handleOnSubmit}
          >
            <div className="label-input">
              <label for="name">Name</label>
              <input type="text" required name="name" />
            </div>
            <div className="label-input">
              <label for="email">Email</label>
              <input type="email" required name="email" />
            </div>
            <div
              sx={{
                display: "none",
              }}
            >
              <input type="hidden" name="_gotcha" />
            </div>
            <div
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <button type="submit">Submit</button>
            </div>
          </form>
        ) : (
          <div>
            Thank you for subscribing! Stay tuned for news and updates delivered
            straight to your inbox.
          </div>
        )}
      </div>
    </div>
  )
}

export default MyForm
