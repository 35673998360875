/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import Serializers from "./serializers"
import Container from "../container"
import "./columns.css"
import LogoSlider from "../LogoSlider"
import TeamTeaser from "../entity/team/teamTeaser"

const Columns = ({ node }) => {
  console.log(node)
  return (
    <section
      id={node.sanityId}
      sx={{
        padding: "25px 0px",
      }}
    >
      <Container>
        {node.title && (
          <h2
            sx={{
              textTransform: "uppercase",
              fontWeight: "400 !important",
              textAlign: "center",
              margin: "25px 0",
            }}
          >
            {node.title}
          </h2>
        )}

        {node.item.find(e => e._type === "blockText") && (
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              img: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            {node.item.map((item, index) => (
              <div
                sx={{
                  padding: "0px 20px",
                }}
                key={index}
              >
                {item && (
                  <BlockContent blocks={item} serializers={Serializers} />
                )}
              </div>
            ))}
          </div>
        )}
        {node.item.find(e => e._type === "team") && (
          <div
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              img: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            {node.item.map((item, index) => (
              <div
                sx={{
                  padding: "0px 0px",
                  width: "100%",
                }}
                key={index}
              >
                {item && <TeamTeaser team={item} />}
              </div>
            ))}
          </div>
        )}
      </Container>

      {!node.item.find(e => e._type === "blockText") &&
        !node.item.find(e => e._type === "team") && <LogoSlider value={node} />}
    </section>
  )
}

export default Columns
