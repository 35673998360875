/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import axios from "axios"

const MyForm = () => {
  let team = ""
  let lname = ""
  let address = ""

  if (typeof window !== "undefined") {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    team = urlParams.get("team")
    lname = urlParams.get("lname")
    address = urlParams.get("address")
  }

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    team: team,
    lname: lname,
    address: address,
  })

  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      if (typeof window !== "undefined") {
        window.location.href = "/contact-thank-you"
      }
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (e.target[5].value === "") {
      setServerState({ submitting: true })
      axios({
        method: "post",
        url: "https://getform.io/f/029647e9-2564-4c34-95b6-8fe0bb22078c",
        data: new FormData(form),
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form)
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form)
        })
    } else {
      console.log("failed")
    }
  }

  return (
    <div>
      <div>
        {serverState.team && (
          <div
            sx={{
              marginBottom: "20px",
            }}
          >
            You are sending this message to
            <strong> {team + " " + lname} </strong>to inquire about
            <strong> {address}.</strong>
          </div>
        )}

        <form
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            maxWidth: "800px",
            select: {
              border: "1px solid #A29A9A",
              color: "#484242",
              backgroundColor: "transparent",
              padding: "8px 10px",
              fontSize: "1rem",
              width: "100%",
              borderRadius: "0px !important",
              boxSizing: "border-box",
            },
            input: {
              border: "1px solid #A29A9A",
              color: "#484242",
              backgroundColor: "transparent",
              padding: "8px 20px",
              fontSize: "1rem",
              width: "100%",
              borderRadius: "0px !important",
              boxSizing: "border-box",
              "&::placeholder": {
                color: "#484242",
              },
              "&:focus-visible": {
                borderRadius: "0px !important",
                outline: "none !important",
              },
            },
            button: {
              border: "1px solid #A29A9A",
              color: "#484242",
              backgroundColor: "transparent",
              padding: "8px 30px",
              fontSize: "1rem",
              cursor: "pointer",
              display: "inline-block",
              marginTop: "20px",
              marginBottom: "20px",
              borderRadius: "18px",
              "&:hover": {
                backgroundColor: "rgba(249, 248, 255, 0.5)",
              },
            },
            ".check-container": {
              display: "flex",
              width: "100%",
              alignItems: "center",
              marginTop: "20px",
              input: {
                width: "20px",
                height: "20px",
                margin: "0px 20px 0px 0px",
              },
              label: {
                width: "calc(100% - 40px)",
              },
            },
            ".label-input": {
              marginBottom: "15px",
              width: "100%",
            },
          }}
          onSubmit={handleOnSubmit}
        >
          <div className="label-input">
            <label for="name">Name*</label>
            <input type="text" required name="name" />
          </div>
          <div className="label-input">
            <label for="email">Email*</label>

            <input type="email" required name="email" />
          </div>
          <div className="label-input">
            <label for="phone">Phone</label>

            <input type="phone" name="phone" />
          </div>
          <div
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>Comments</div>
            <textarea
              sx={{
                height: "150px",
                background: "transparent",
                padding: "8px 20px",
                fontSize: "1rem",
                fontFamily: "helvetica",
                color: "#484242",
              }}
              required
              name="comments"
            />
          </div>
          <input type="hidden" name="team" value={serverState.team} />
          <div
            sx={{
              display: "none",
            }}
          >
            <input type="hidden" name="_gotcha" />
          </div>
          <div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MyForm
