/** @jsx jsx */
import { jsx } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import BlockContent from "@sanity/block-content-to-react"
import Serializers from "../../serializers/serializers"
import sanityConfig from "../../../sanityConfig"
import ContactData from "./contactData"

const TeamTeaser = ({ node, team }) => {
  // const team = node
  let team2 = null
  if (team) {
    team2 = team
  }
  if (node) {
    team2 = node
  }
  const imageData = getGatsbyImageData(
    team2.teamPhoto.asset.id,
    { height: 400, width: 400 },
    sanityConfig
  )
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "row"],
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: ["column"],
          justifyContent: "space-between",
        }}
      >
        <div
          sx={{
            width: "200px",
            margin: ["0", "0"],
          }}
        >
          {team2.teamPhoto && (
            <GatsbyImage
              sx={{
                maxWidth: "100%",
                height: "auto",
                boxShadow: "7px 5px 8px 1px rgba(0,0,0,0.28)",
                marginRight: ["0", "1rem"],
              }}
              image={imageData}
              width={200}
              height={300}
              alt="Team Photo"
              title="Team Photo"
            />
          )}
        </div>
        <ContactData team={team2} mobile={true} />
      </div>
      <div
        sx={{
          width: "100%",
          marginLeft: ["0px", "25px"],
          a: { textDecoration: "underline" },
        }}
      >
        <ContactData team={team2} mobile={false} />
        <BlockContent blocks={team2.teamBio} serializers={Serializers} />
      </div>
    </div>
  )
}

export default TeamTeaser
