/** @jsx jsx */
import { jsx } from "theme-ui"

const ContactData = ({ team, mobile }) => {
  if (mobile) {
    return (
      <div
        sx={{
          display: ["block", "none"],
          marginTop: ["1rem", "0"],
        }}
      >
        <div
          sx={{
            display: ["flex"],
            flexDirection: ["column"],
          }}
        >
          <div
            sx={{
              h2: {
                marginTop: "0px",
                marginBottom: "5px",
                fontFamily: "body",
                fontSize: "24px",
                fontWeight: "500",
              },
            }}
          >
            <h2>
              {team.teamFirstName} {team.teamLastName}
            </h2>
            <div>{team.teamPosition}</div>
          </div>
          <div
            sx={{
              a: {
                textDecoration: "underline",
                color: "#18459A",
              },
            }}
          >
            <div>
              <a href={"tel:" + team.teamCellNumber}>{team.teamCellNumber}</a>
            </div>
            <div>
              <a href={"mailto:" + team.teamEmail}>{team.teamEmail}</a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      sx={{
        display: ["none", "block"],
        marginTop: ["0"],
      }}
    >
      <div
        sx={{
          display: ["flex"],
          gap: "3rem",
          justifyContent: "space-between",
        }}
      >
        <div
          sx={{
            h2: {
              marginTop: "0px",
              marginBottom: "5px",
              fontFamily: "body",
              fontSize: "24px",
              fontWeight: "500",
            },
          }}
        >
          <h2>
            {team.teamFirstName} {team.teamLastName}
          </h2>
          <div>{team.teamPosition}</div>
        </div>
        <div
          sx={{
            a: {
              color: "#18459A",
              textDecoration: "underline",
            },
          }}
        >
          <div>
            <a href={"tel:" + team.teamCellNumber}>{team.teamCellNumber}</a>
          </div>
          <div>
            <a href={"mailto:" + team.teamEmail}>{team.teamEmail}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactData
