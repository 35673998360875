/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

const SocialLinks = ({ dark = false }) => {
  const data = useStaticQuery(query)
  return (
    <div sx={{ display: "flex", width: "100px" }}>
      <a
        href="https://www.facebook.com/simpsonranches"
        target="_blank"
        rel="noreferrer"
        title="Facebook"
      >
        <img
          sx={{
            width: "27px",
            fill: "#000",
          }}
          src={dark ? data.facebookDark.publicURL : data.facebook.publicURL}
          alt="Facebook"
          title="Facebook"
        />
      </a>
      <a
        href="https://twitter.com/SimpsonRanches"
        target="_blank"
        rel="noreferrer"
        title="Twitter"
        sx={{
          marginLeft: "10px",
        }}
      >
        <img
          sx={{
            width: "27px",
          }}
          src={dark ? data.twitterDark.publicURL : data.twitter.publicURL}
          alt="Twitter"
          title="Twitter"
        />
      </a>
      <a
        href="https://www.instagram.com/simpsonranches/"
        target="_blank"
        rel="noreferrer"
        title="Instagram"
        sx={{
          marginLeft: "10px",
        }}
      >
        <img
          sx={{
            width: "27px",
          }}
          src={dark ? data.instagramDark.publicURL : data.instagram.publicURL}
          alt="Instagram"
          title="Instagram"
        />
      </a>
    </div>
  )
}

export default SocialLinks

const query = graphql`
  query SocialLinksQuery {
    facebook: file(name: { eq: "sim-Facebook" }) {
      name
      publicURL
    }
    twitter: file(name: { eq: "sim-Twitter" }) {
      name
      publicURL
    }
    instagram: file(name: { eq: "sim-Instagram" }) {
      name
      publicURL
    }
    facebookDark: file(name: { eq: "sim-Facebook-dark" }) {
      name
      publicURL
    }
    twitterDark: file(name: { eq: "sim-Twitter-dark" }) {
      name
      publicURL
    }
    instagramDark: file(name: { eq: "sim-Instagram-dark" }) {
      name
      publicURL
    }
  }
`
