/** @jsx jsx */
import { jsx } from "theme-ui";
import "./LogoSlider.scss";

const LogoSlider = ({ value }) => {
  const handleClick = url => {
    if (typeof window !== "undefined" && window) {
      window.open(url, "_blank").focus()
    }
  }

  return (
    <div
      className="logo-slide-container"
      sx={{
        ul: {
          margin: "0px",
          padding: "0px",
        },
        li: {
          listStyle: "none",
        },
      }}
    >
      <ul className="logo-slide-inner first">
        {value.item.map((logo, index) => (
          <li key={index} onClick={() => handleClick(logo.url)}>
            <img src={logo.desktopVersion.asset.url} />
          </li>
        ))}
      </ul>
      <ul className="logo-slide-inner">
        {value.item.map((logo, index) => (
          <li key={index} onClick={() => handleClick(logo.url)}>
            <img src={logo.desktopVersion.asset.url} />
          </li>
        ))}
      </ul>
      <ul className="logo-slide-inner delay">
        {value.item.map((logo, index) => (
          <li key={index} onClick={() => handleClick(logo.url)}>
            <img src={logo.desktopVersion.asset.url} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LogoSlider;
