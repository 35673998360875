import * as React from "react"
import { Carousel } from "react-responsive-carousel"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Container from "../container"
import sanityConfig from "../../sanityConfig"

import { Link } from "gatsby"

import "react-responsive-carousel/lib/styles/carousel.min.css"

const Image = ({ image }) => {
  const imageData = getGatsbyImageData(
    image.asset.id,
    { maxWidth: 1920 },
    sanityConfig
  )
  const bgImage = convertToBgImage(imageData)
  return (
    <BackgroundImage
      Tag="div"
      {...bgImage}
      preserveStackingContext
      sx={{
        padding: "100px 60px",
      }}
      alt="Carousel Item"
      title="Carousel Item"
    />
  )
}
const PropertyCarousel = ({ node }) => {
  return (
    <section id={node.sanityId}>
      <Container fullWidth noMobilePadding={true}>
        <Carousel
          stopOnHover={false}
          dynamicHeight={false}
          autoPlay
          showThumbs={false}
          infiniteLoop
          showIndicators={false}
          showArrows={true}
          showStatus={false}
          transitionTime={500}
          interval={8000}
          animationHandler="fade"
          swipeable={false}
        >
          {node.body.map((el, index) => (
            <div className={"carousel-image"} key={index}>
              <Image
                image={
                  el.propertyFeaturedImage
                    ? el.propertyFeaturedImage
                    : el.propertyImages[0]
                }
              />

              <div className="property-detail">
                <div className="detail-body">
                  <b>{el.propertyName}</b>
                  <p>
                    <span>
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.91342 15.5301C4.91342 15.5301 9.67304 9.21965 9.67304 6.0922C9.67304 2.96474 7.54209 0.429443 4.91342 0.429443C2.28476 0.429443 0.153809 2.96474 0.153809 6.0922C0.153809 9.21965 4.91342 15.5301 4.91342 15.5301ZM4.91344 7.9798C5.78966 7.9798 6.49998 7.1347 6.49998 6.09222C6.49998 5.04974 5.78966 4.20464 4.91344 4.20464C4.03722 4.20464 3.3269 5.04974 3.3269 6.09222C3.3269 7.1347 4.03722 7.9798 4.91344 7.9798Z"
                          fill="#F7F7F7"
                        />
                      </svg>{" "}
                      {el.county.countyName} County
                    </span>
                    <span>
                      {el.acreage
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ""}
                      ± acres
                    </span>
                  </p>
                </div>
                <div className="detail-cta">
                  <Link className="btn" to={`/listings/${el.slug.current}`}>
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </Container>
    </section>
  )
}

export default PropertyCarousel
