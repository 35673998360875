/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Footer from "./footer"

const Layout = ({ children }) => (
  <div
    sx={{
      fontFamily: "body",
      fontWeight: "400",
      fontSize: "2",
      lineHeight: "body",
      letterSpacing: "0px",
      h1: {
        fontFamily: "heading",
        fontWeight: "heading",
        lineHeight: "heading",
        fontSize: "3rem",
        margin: "1rem 0rem",
      },
      h2: {
        fontFamily: "heading",
        fontWeight: "heading",
        lineHeight: "heading",
        fontSize: "2rem",
        margin: "1rem 0rem",
      },
      p: {
        a: {
          color: "#18459A !important",
          textDecoration: "underline !important",
        },
      },
    }}
  >
    <main sx={{ bg: "antler" }}>{children}</main>

    <Footer></Footer>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
